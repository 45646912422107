import {
  Flag,
  ProductRaw, ProductVariantParametrics,
  ProductVariantRaw
} from '~/constants/types/algolia';
import Parametric, { ParametricModel } from '~/models/parametric';
import ProductVariant, { ProductVariantModel } from '~/models/productVariant';
import {
  NorceMediaSetCodes,
  NorceMediaTypes,
  NorceParametricCodes,
  NorceFlagGroupCodes,
  NorceFlagCodes
} from '~/constants/norceCodes';

export type ProductModel = Product & ProductRaw

export type ProductImageAndVideo = {
  file: string;
  type: 'video' | 'image';
  linkedVideo?: string; // if videoPreview, this is a link to the video file
}

export default class Product {
  _ProductRaw: ProductRaw;
  variants: ProductVariantModel[];
  primaryVariant = 0;
  /**
   * if we know we haven't loaded all variants, only a single variant
   * @important. Since a product can be just one variant, does not indicate a single variant, only that we don't know if there are more
   * usecase: in ProductList we only load single variants, not entire products
   */
  limitedVariantLoaded = false;

  constructor(ProductRawArr: ProductRaw[], limitedVariantLoaded = false) {

    let primaryVariant = ProductRawArr.findIndex((f) => f.isPrimary);
    if (primaryVariant === -1) { primaryVariant = 0;}

    this._ProductRaw = ProductRawArr[primaryVariant];
    this.limitedVariantLoaded = limitedVariantLoaded;
    this.variants = ProductRawArr.map((pr) => {
      /**
       //image?: string,
       */
      const thisVariant = {
        definingParametrics: pr.variantDefiningParametrics,
        flags: pr.variantFlags,
        isIncludedInStructureArticle: pr.isIncludedInStructureArticle,
        isPrimary: pr.isPrimary,
        isStructureArticle: pr.isStructureArticle,
        mediaSets: pr.mediaSets,
        name: pr.variantName,
        parametrics: pr.variantParametrics,
        partNo: pr.partNo,
        priceFetchPartNo: pr.priceFetchPartNo,
        priceInfo: pr.variantPriceInfo,
        productParametrics: pr.parametrics,
        sort: pr.sort,
        status: pr.variantStatus,
        stockStatuses: pr.variantStockStatuses,
        structureArticlePartNumbers: pr.structureArticlePartNumbers,
        structureArticleProducts: pr.structureArticleProducts,
        url: pr.variantUrl,
      } as ProductVariantRaw;

      if (pr.variantImage) {
        thisVariant.image = pr.variantImage;
      }

      return ProductVariant.create(thisVariant);
    }).sort((a, b) => {
      return this._ProductRaw.partNumbers.indexOf(a.partNo) - this._ProductRaw.partNumbers.indexOf(b.partNo);
    });

    // since we sorted variants, find primary again
    primaryVariant = this.variants.findIndex((f) => f.isPrimary);
    if (primaryVariant === -1) { primaryVariant = 0;}
    this.primaryVariant = primaryVariant;

    return new Proxy(this, {
      get(target, name, receiver) {
        if (Reflect.has(target, name)) {
          return Reflect.get(target, name, receiver);
        }
        return Reflect.get(target._ProductRaw, name, receiver);
      },
    });
  }

  static create(ProductRawArr: ProductRaw | ProductRaw[], limitedVariantLoaded = false): ProductModel {
    if (!Array.isArray(ProductRawArr)) {
      ProductRawArr = [ ProductRawArr ];
    }
    return new Product(ProductRawArr, limitedVariantLoaded) as ProductModel;
  }

  /**
   * Since each variant has different partNo, i don't know how we use this
   */
  get partNo() {
    return this._ProductRaw.partNo;
  }

  get priceFetchPartNo() {
    return this.variants[0].priceFetchPartNo;
  }

  get mainImage() {
    return this._ProductRaw.image || '';
  }

  /**
   * Gets main image, if that missing, use image on product level
   */
  get listImage(): string {
    return this._ProductRaw.image || this.variants[this.primaryVariant].image || '';
  }

  /**
   * This is just for PDP extended layout, so we don't get all variants in the slideshow
   */
  get mainProductImage(): ProductImageAndVideo[] {
    const output: string[] = [];
    if (this._ProductRaw.image && this._ProductRaw.image !== '') {
      output.push(this._ProductRaw.image);
    }
    return output.map((i) => {
      return {
        file: i,
        type: 'image',
      };
    });
  }

  imagesAndVideo(type: NorceMediaSetCodes = NorceMediaSetCodes.DefaultImages, activeVariant?: ProductVariantModel, maxImages = 1000, videoPosition = 1) {
    const additionalImages = this.additionalImages(activeVariant);
    activeVariant?.image && additionalImages.unshift(activeVariant?.image);

    let videos = [] as ProductImageAndVideo[];
    const output: ProductImageAndVideo[] = additionalImages.map((i)=> {
      return {
        file: i,
        type: 'image',
      };
    });
    if (type === NorceMediaSetCodes.DefaultImages) {
      const videosIn = this._ProductRaw.mediaSets?.filter(
        mediaSet => mediaSet.code === NorceMediaSetCodes.Video || mediaSet.code === NorceMediaSetCodes.MainVideo);
      if (videosIn && videosIn.length) {
        videos = videosIn.map((i) => {
          return {
            file: this._ProductRaw.image ?? '',
            linkedVideo: i.media[0]?.url ?? '',
            type: 'image',
          };
        });
      }
    }
    if (output.length > 0 && videos.length > 0) {
      if (videoPosition > output.length) {
        videoPosition = output.length;
      }
      output.splice(videoPosition, 0, ...videos);
      return output.slice(0, maxImages);
    }
    return videos.concat(output).slice(0, maxImages);
  }

  additionalImages(activeVariant?: ProductVariantModel) {
    return activeVariant?.mediaSets
      ?.find(mediaSet => mediaSet.code === NorceMediaSetCodes.AdditionalImages)
      ?.media?.filter((m) => m.type === NorceMediaTypes.Image).map((m) => {
        return m.url;
      }) ?? [];
  }

  get imageList(): string[] {
    const output = this.mainImage !== '' ? [ this.mainImage ] : [];
    let additional = [] as string[];
    const mediaSet = this._ProductRaw.mediaSets.find(mediaSet => mediaSet.code === NorceMediaSetCodes.AdditionalImages);
    if (mediaSet) {
      additional = mediaSet.media?.filter((m) => m.type === NorceMediaTypes.Image).map((m) => {
        return m.url;
      });
    }
    return output.concat(additional);
  }

  get documentList(): {url: string, label: string}[] {
    let documents = [] as {url: string, label: string}[];

    this._ProductRaw.mediaSets.filter(mediaSet => mediaSet.code === NorceMediaSetCodes.Msds || mediaSet.code === NorceMediaSetCodes.UserManual).forEach((doc) => {
      const docUrl = doc.media.filter((m) => m.type === NorceMediaTypes.Image).map((m) => m.url)[0] ?? '';
      if (docUrl) {
        documents = [...documents, { url: docUrl, label: doc.title }];
      }
    });

    return documents;
  }

  get productCardDisplayFlags(): Flag[] {
    let flags = this.displayFlags.concat(this.splashes);
    if (this._ProductRaw.isStructureArticle) {
      flags = flags.filter((flag) => flag.code !== NorceFlagCodes.PreOrder);
    }
    return flags;
  }

  get displayFlags(): Flag[] {
    const flags: Flag[] = [];

    if (this.extendedLayout) {
      const { $t } = useNuxtApp();
      flags.push({
        code: 'colorMap',
        text: $t('productList.flags.colorMap'),
        background: '#000000',
      });
    }

    const productFlags = Array.isArray(this._ProductRaw.flags) ? this._ProductRaw.flags : [];
    productFlags.forEach((flag) => {
      if (flag.groupCode === NorceFlagGroupCodes.DisplayFlags) {
        flags.push(flag);
      }
    });

    if (this.variants.length > 0) {
      [NorceFlagCodes.PreOrder, NorceFlagCodes.DiscontinuedAndVisible, NorceFlagCodes.OnDemand].map(flag => {
        const variantsWithFlags = this.variants.filter(v => v.flags?.find(f => f.code === flag));
        if (variantsWithFlags.length === this.variants.length) {
          flags.push({
            code: flag,
            text: variantsWithFlags[0].flags?.filter(f => f.code === flag)[0].text ?? '',
          });
        }
      });
    }
    return flags;
  }

  get splashes(): Flag[] {
    return this._ProductRaw.flags.filter(flag => flag.groupCode === NorceFlagGroupCodes.Splashes) ?? [];
  }

  get sizes() {
    return this.variants;
  }

  relatedProductsByType(type: string) {
    return this.relatedProducts?.filter(data => data.code === type)?.map(data => data.partNo) ?? [];
  }

  get parametrics(): ParametricModel[] {
    return this._ProductRaw.parametrics?.map(p => Parametric.create(p)) ?? [];
  }

  parametricByCode(code: string) {
    return this.parametrics?.find(p => p.code === code);
  }

  get color() {
    return this._ProductRaw.colorInfo?.name;
  }

  get relatedProducts() {
    return this._ProductRaw.relatedProducts ?? [];
  }

  get priceInfo() {
    return {
      price: this._ProductRaw.priceInfo?.price || '',
      priceDisplay: this._ProductRaw.priceInfo?.priceDisplay || '',
      priceBeforeDiscount: this._ProductRaw.priceInfo?.priceBeforeDiscount || '',
      priceBeforeDiscountDisplay: this._ProductRaw.priceInfo?.priceBeforeDiscountDisplay || '',
      priceBeforeVat: this._ProductRaw.priceInfo?.priceBeforeVat || '',
      priceBeforeVatDisplay: this._ProductRaw.priceInfo?.priceBeforeVatDisplay || '',
      priceBeforeDiscountBeforeVatDisplay: this._ProductRaw.priceInfo?.priceBeforeDiscountBeforeVatDisplay || '',
      vatRate: this._ProductRaw.priceInfo?.vatRate || 0,
      isOnSale: this._ProductRaw.priceInfo.isOnSale ?? (this._ProductRaw.priceInfo.price !== this._ProductRaw.priceInfo.priceBeforeDiscount),
    };
  }

  get hasOverrideMetaTitle(): boolean {
    return !!(this.parametrics.find(p => p.code === NorceParametricCodes.MetaTitleOverride));
  }
  get metaTitle(): string | null {
    const override = this.parametrics.find(p => p.code === NorceParametricCodes.MetaTitleOverride)?.data;
    return (override && override.length) ? (override as string) : this.parametrics.find(p => p.code === NorceParametricCodes.MetaTitle)?.description ?? null;
  }

  /**
   * uses short description. If not available, uses truncated regular desription
   */
  get metaDescription(): string | null {
    const short = this._ProductRaw.shortDescription;
    if (short) {
      const output1 = short.replace(/(<([^>]+)>)/gi, '').trim();
      if (output1.length > 0) {
        return output1;
      }
    }
    const long = this._ProductRaw.description;
    if (long) {
      const output2 = long.replace(/(<([^>]+)>)/gi, '').trim();
      if (output2.length > 0) {
        return output2.slice(0,100);
      }
    }

    return '';
  }

  get productUnavailable() {
    return this.parametricByCode(NorceParametricCodes.ProductUnavailable)?.value || false;
  }

  get ingredients() {
    return this.parametricByCode(NorceParametricCodes.Ingredients)?.value || null;
  }

  get volume() {
    return this.parametricByCode(NorceParametricCodes.Volume) || null;
  }

  get characteristics() {
    return this.parametricByCode(NorceParametricCodes.Characteristics) || [];
  }

  get isNewProduct() {
    return this._ProductRaw.flags?.filter((flag: Flag) => flag.type === NorceFlagCodes.IsNew)?.length;
  }

  get isPercentageCampaign() {
    return this._ProductRaw.variantFlags?.filter((flag: Flag) => flag.groupCode === 'percentageCampaigns')?.length > 0;
  }
  get isStepCampaigns() {
    return this._ProductRaw.variantFlags?.filter((flag: Flag) => flag.groupCode === 'stepCampaigns')?.length > 0;
  }
  get isGiftCampaign() {
    return this._ProductRaw.variantFlags?.filter((flag: Flag) => flag.groupCode === 'giftCampaigns')?.length > 0;
  }

  get extendedLayout(): boolean {
    return this.parametricByCode(NorceParametricCodes.ProductLayout)?.listValue?.code === 'extended';
  }

  /**
   * Looks for a variant that match the partNo in root
   */
  get mainVariant(): ProductVariant | null {
    const variant = this.variants.find((f)=> f.partNo === this._ProductRaw.partNo);
    if (variant) {
      return ProductVariant.create(variant);
    }
    return null;
  }

  variantByUrl(url: string): ProductVariantModel {
    const variant = this.variants.find((f) => f.url === url);
    if (variant) {
      return variant;
    }
    return this.variants[this.primaryVariant];
  }

  get allVariantDefiningParametrics() {
    const output = [] as ProductVariantParametrics[];
    this.variants.forEach((v) => {
      v.definingParametrics.forEach((i)=> {
        const exist = output.findIndex((fi)=> fi.code === i.code);
        const thisI = JSON.parse(JSON.stringify(i));
        if (exist === -1) {
          // this was the old code, i have no idea what it does, but now to doesn't crash the site
          // thisI.value ? [{ name: thisI.value.toString(), code: thisI.value.toString(), uom: thisI.uom }] : (thisI.listValue ? [thisI.listValue] : thisI.multipleValues),
          let thisValues = null as any;
          if (thisI.value) {
            thisValues =  [{ name: thisI.value.toString(), code: thisI.value.toString(), uom: thisI.uom }];
          }
          else if (thisI.listValue) {
            thisValues = [thisI.listValue];
          } else if (thisI.multipleValues) {
            thisValues = thisI.multipleValues;
          }
          if (thisValues) {
            output.push({
              name: thisI.name,
              code: thisI.code,
              values: thisValues,
            });
          }
        } else {
          output[exist].values = output[exist].values.concat(thisI.value ? [{ name: thisI.value.toString(), code: thisI.value.toString(), uom: thisI.uom }] : (thisI.listValue ? [thisI.listValue] : thisI.multipleValues));
        }
      });
    });
    return output
      .filter((of) => of.code !== null)
      .map((om) => {
        return {
          ...om,
          values: om.values.filter(
            (value, index, self) => self.findIndex((v) => v?.name === value?.name) === index
          ),
        };
      });
  }

}
